const theme = {
  background: '#0b0b1e',
  colors: {
    primary: '#AA33B5',
    secondary: '#646693',
    klvPurple: '#7D3FF1',

    white: '#fff',
    darkTheme: '#c6c7eb',
    fadedBlue: '#595c98',
    mistBlue: '#4F5185',
    navyBlue: '#27284e',
    lightGreen: ' #4ebc87',
    black: '#000',
    golden: '#ffb342',
    strongPink: '#fc0fc0',

    gray: '#F2F2F2',
    transparentGray: '#ECECF1',
    lightGray: 'rgba(100, 102, 147, 0.2);',

    purple: '#7B7DB2',
    lightPurple: '#515395',
    lightinPurple: '#646693',
    mistPurple: ' #D9DAF2',
    darkPurple: '#222345',
    lightPink: '#FFF6D5',

    red: '#F43942',
    lightRed: '#FFC7C7',
    purpleModal:
      'linear-gradient(360deg, rgba(94, 96, 162, 0) 0%, rgba(78, 80, 146, 0.2) 97%)',
    walletGradient:
      'linear-gradient(180deg, #222345 0%, rgba(34, 35, 69, 0) 100%)',
    pinkGradient: 'linear-gradient(114.7deg, #dc3f89 31.5%, #903edd 100%)',
    purpleGradient: 'linear-gradient(135deg, #ff4681 1.18%, #9b44f6 75.21%)',
    yellowBNBGrad:
      'linear-gradient(180deg, #f0b90b 0%, rgba(240, 185, 11, 0) 100%)',
    ethGradient:
      'linear-gradient(180deg, #0064c4 0%, rgba(0, 100, 196, 0) 100%)',
    trxGradient:
      'linear-gradient(180deg, #ef0027 0%, rgba(0,100, 196, 0) 100%)',
    klvGradient:
      'linear-gradient(180deg, #7D3FF1 0%, rgba(0,100, 196, 0) 100%)',
    redGradient: 'linear-gradient(180deg, #F43942 0%, #7e070d 100%)',
    greenGradient: 'linear-gradient(180deg, #37dd72 0%, #105c2b 100%)',
    successGreen: '#37DD72',
    failedRed: ' #7e070d',
  },

  text: {
    dark: '#27284E',
    red: '#9E1313',
  },

  boxShadow: {
    successShadow: '0 0 15px rgb(235,207,0 ,0.8)',
    redShadow: '0 0 15px rgb(0, 0, 0, 0.6)',
  },

  pin: {
    border: '#27284E',
    focus: '#C6C7EB',
  },

  settings: {
    backgoundGradient:
      'linear-gradient(180deg, #222345 0%, rgba(34, 35, 69, 0) 100%)',
    title: '#585A92',
    divider: '#343560',
    removeWallet: {
      warning: 'rgba(255, 227, 128, 0.4)',
    },
  },
};

export default theme;
