import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const apiKey =
  process.env.REACT_APP_BUGSNAG_API_KEY || '6a78e1532e6f4c4183786f8a94df7c8e';

Bugsnag.start({
  apiKey: apiKey,
  plugins: [new BugsnagPluginReact(React)],
  onError: function (event) {
    event.errors[0].stacktrace = event.errors[0].stacktrace.map(function (
      frame,
    ) {
      frame.file = frame.file.replace(
        /chrome-extension:/g,
        'chrome_extension:',
      );
      frame.file = frame.file.replace(/moz-extension:/g, 'moz_extension:');
      return frame;
    });
  },
});

export default Bugsnag;
