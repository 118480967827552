import styled from 'styled-components';

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: black;
  color: white;
  padding-inline: 20px;
  padding-block: 1rem;
  align-items: center;
  justify-content: center;
  min-width: 1rem;
  width: 100%;
  border-bottom: 1px solid #2c2c2c;

  div {
    h1 {
      display: none;
    }

    a {
      display: none;
    }

    h2 {
      display: none;
    }
  }

  @media (min-width: 1028px) {
    justify-content: space-between;
    padding-inline: 20%;
    div {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;

      a,
      a:hover,
      a:visited,
      a:link,
      a:active {
        text-decoration: none;
        padding-left: 2rem;
        font-size: 15px;
        font-weight: 700;
        color: white;
        display: flex;
      }

      h2 {
        display: flex;
        padding-left: 2rem;
        font-size: 15px;
        font-weight: 700;
        color: #646693;
      }
    }
  }
`;

export const InfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  padding-top: 92px;
  gap: 148px;
  img {
    display: none;
  }

  @media (min-width: 1028px) {
    img {
      display: flex;
      max-width: 450px;
      border-radius: 50px;
    }
  }
`;

export const KSUpdateContainer = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 20rem;
  gap: 35px;
  h1 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 70px;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 36px;
  }

  @media (min-width: 1028px) {
    max-width: 25rem;

    h1 {
      font-size: 5rem;
      font-weight: 500;
      line-height: 90px;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 36px;
    }
  }
`;

export const LogoImg = styled.img`
  height: inherit;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #aa33b5;
  height: 4.5rem;
  border-radius: 4px;
  &:hover {
    background-color: #882293;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 300;
    user-select: none;
  }
`;

export const LatestVersion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #2c2c2c;
  padding-top: 1rem;
  h1 {
    margin: 0;
    padding: 0;
    line-height: 12px;

    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: 2px;
    color: #646693;
  }

  h2 {
    margin: 0;
    padding: 0;
    line-height: 12px;

    font-weight: 700;
    font-size: 0.8rem;
    color: white;
  }
`;

export const BodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  gap: 1rem;
  flex: 1 1 0px;
`;

export const UpdateHistoryDiv = styled.div`
  margin-top: 5rem;
  width: 63rem;
  max-width: 80%;
  min-width: 10rem;
  margin-bottom: 5rem;
  border: 1px solid #2c2c2c;
  @media (min-width: 1028px) {
    max-width: 100%;
  }
`;

export const UpdateFwHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UpdateHistoryHeaderItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 15rem;
  padding-block: 2rem;
  padding-inline: 3rem;
  border: 10px;
  transition: border-bottom 1s linear;
  border-bottom: ${p => (p.selected ? '10px' : '0')} solid white;

  h1 {
    font-size: 25px;
    line-height: 35px;
    font-weight: 500;
    color: white;
    user-select: none;
  }
  @media (min-width: 1028px) {
    h1 {
      font-size: 48px;
      line-height: 64px;
      font-weight: 500;
      color: white;
    }
  }
`;

export const FirmwareHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #202130;
  min-height: 4rem;
  h1,
  h2 {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    color: white;
    color: #aa33b5;
    text-align: center;
  }

  h1 {
    width: 25%;
    min-width: 7rem;
  }

  h2 {
    width: 75%;
  }

  @media (min-width: 1028px) {
    h1,
    h2 {
      font-size: 14px;
      line-height: 36px;
      font-weight: 500;
      letter-spacing: 2px;
      color: white;
      color: #aa33b5;
    }
  }
`;

export const NoteCardDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #101120;
  border-top: 1px solid #2c2c2c;
`;

export const NoteVersionDiv = styled.div`
  width: 25%;
  min-width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }

  @media (min-width: 1028px) {
    h1 {
      font-size: 22px;
    }
  }
`;
export const NoteDiscriptionDiv = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 3rem;
  width: 75%;
  h1 {
    color: white;
    font-size: 10px;
    font-weight: 500;
    line-height: 0.6rem;
  }

  @media (min-width: 1028px) {
    h1 {
      font-size: 20px;
    }
  }
`;

export const ShadowDiv = styled.div<{ Shadow: boolean }>`
  filter: brightness(${p => (p.Shadow ? 20 : 100)}%);
  -webkit-filter: brightness(${p => (p.Shadow ? 20 : 100)}%);
`;

export const BaseDiv = styled.div<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  opacity: ${p => (p.isVisible ? '1' : '0')};
  transition: opacity 0.5s;
  background-color: black;
`;

export const ModalDiv = styled.div`
  display: flex;
  background-color: black;
`;

export const KsafeInstructionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const KsafeInstructionsImgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  img {
    height: 100px;
  }
`;

export const KsafeInstructionsDisc = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  h1 {
    color: white;
    font-weight: 500;
    font-size: 30px;
    line-height: 80px;
  }
`;
