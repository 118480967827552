import React from 'react';
import { PercentageDiv, PercentageImgBack, PercentageImgFront } from './styles';
import svgKs from '../../assets/kleverSafe.svg';
export const LoadingImg: React.FC<{ show: boolean }> = props => {
  return (
    <PercentageDiv isVisible={props.show}>
      <PercentageImgFront src={svgKs} height="200px" />
    </PercentageDiv>
  );
};
