import Button from 'components/Button';
import { ButtonDiv, ModalContainer } from './styles';
import React from 'react';
import fusgif from '../../assets/gifs/fus.gif';
import done from '../../assets/checked.png';
export const WarningVersionModal: React.FC<{
  isVisible: boolean;
  yes: () => void;
  no: () => void;
}> = props => {
  return (
    <ModalContainer isVisible={props.isVisible}>
      <h1>Warning:</h1>
      <h2>
        You already have the latest firmware release!
        <br />
        Do you want to proceed with the update?
      </h2>
      <div>
        <ButtonDiv>
          <Button
            title="Yes"
            onClick={() => {
              props.yes();
            }}
          ></Button>
        </ButtonDiv>
        <ButtonDiv>
          <Button
            title="No"
            onClick={() => {
              props.no();
            }}
          ></Button>
        </ButtonDiv>
      </div>
    </ModalContainer>
  );
};
