import styled from 'styled-components';

export const ModalDiv = styled.div<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  opacity: ${p => (p.isVisible ? '1' : '0')};
  transition: opacity 1s;
  position: absolute;
  top: 50%;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalProgress = styled.div`
  width: 22rem;
  height: 22rem;
  background-color: #171732;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  h1 {
    padding-top: 1rem;
    color: white;
  }
`;
export const BlinkImage = styled.img<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  opacity: ${p => (p.isVisible ? '1' : '0')};
  transition: opacity 0.2 s;
  position: absolute;
  top: 4.9rem;
  left: 9.65rem;
`;
export const ModalInfo = styled.div`
  height: 22rem;
  width: 30rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  gap: 2rem;
  h1 {
    font-weight: 500;
  }
  div {
    h1 {
      font-weight: 300;
      font-size: 15px;
    }
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 1rem;
    background-color: #ffe380;
  }
`;
