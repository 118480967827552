import React, { useState } from 'react';
import Loader from '../Loader';

import { Container, LoaderContainer } from './styles';

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  feedbackTitle?: string;
  disabled?: boolean;
  onClick?(): void;
  loading?: boolean;
}

const Button: React.FC<IButton> = ({
  title,
  feedbackTitle,
  disabled,
  onClick,
  type = 'button',
  loading,
  ...rest
}) => {
  const [buttonTitle, setButtonTitle] = useState<string>(title || '');

  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (feedbackTitle) {
      setButtonTitle(feedbackTitle);

      setTimeout(() => {
        setButtonTitle(title || '');
      }, 1500);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Container disabled={disabled} onClick={handleClick} type={type} {...rest}>
      {loading ? (
        <LoaderContainer>
          <Loader inline={true} />
        </LoaderContainer>
      ) : (
        <span>{buttonTitle}</span>
      )}
    </Container>
  );
};

export default Button;
