import styled, { keyframes } from 'styled-components';

export const PercentageDiv = styled.div<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  opacity: ${p => (p.isVisible ? '1' : '0')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 20rem;
  height: 12rem;
  align-items: center;
  margin: 1rem;
  img {
    position: absolute;
    margin-left: 4.4rem;
    align-self: center;
  }
`;

export const PercentageImgFront = styled.img`
  animation: opacity 2s linear infinite;
  filter: invert(26%) sepia(100%) saturate(1533%) hue-rotate(271deg)
    brightness(85%) contrast(93%);
  @keyframes opacity {
    0% {
      opacity: 100%;
    }

    50% {
      opacity: 0%;
    }
  }
`;

export const PercentageImgBack = styled.img`
  animation: per 2s linear infinite;
`;
