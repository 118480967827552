import React, { Suspense } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

import ReactDOM from 'react-dom';
import Bugsnag from './lib/bugsnag';
import { MemoryRouter } from 'react-router-dom';
import App from './App';
import './i18n';

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const children = (
  <MemoryRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </MemoryRouter>
);

//TODO: Add a proper supense fallback
ReactDOM.render(
  ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : children,
  document.getElementById('root'),
);
