import styled, { css } from 'styled-components';

interface IProps {
  disabled?: boolean;
}

const disabledProps = css`
  opacity: 0.3;
  cursor: not-allowed;

  &:hover {
    transform: scale(1);
    filter: brightness(1);
  }
`;

export const Container = styled.button<IProps>`
  border: none;
  padding: 0.75rem;

  width: 100%;

  display: flex;
  justify-content: center;

  background-color: ${props => props.theme.colors.primary};

  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 0.25rem;

  cursor: pointer;

  transition: 0.2s ease;

  &:hover {
    filter: brightness(1.2);
  }
  ${props => props.disabled && disabledProps}

  &.disabled {
    opacity: 60%;
    cursor: unset;
  }

  &.disabled:hover {
    filter: none;
  }

  &.inverted {
    background-color: transparent;
  }

  span {
    font-weight: 500;
    color: ${props => props.theme.colors.white};
  }
`;

export const LoaderContainer = styled.div`
  transform: translateX(-50%);
`;
