import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalContainer = styled.div<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  opacity: ${p => (p.isVisible ? '1' : '0')};
  transition: opacity 1s;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
  z-index: 3;
  min-width: 20rem;
  top: 50%;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  h1 {
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0rem;
    font-size: 1.5rem;
  }
  h2 {
    padding: 0rem;
    font-size: 0.9rem;
    color: #ac88b9;
  }
  img {
    padding: 1rem;
    border-radius: 1rem;
  }
`;

export const ButtonDiv = styled.div`
  width: auto;
  min-width: 15rem;
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  button {
    font-size: 1rem;
  }
  z-index: 2;
`;
