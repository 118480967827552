import Button from 'components/Button';
import React from 'react';
import fusgif from 'assets/KleverSafeButton.svg';
import { ModalContainer } from './styles';
export const EnterBootModeSoft = (show: boolean) => {
  return (
    <ModalContainer isVisible={show}>
      <h1>Please enter on update mode</h1>
      <h2>Confirm the operation by pressing the accept button</h2>
      <img src={fusgif} height="200px" />
    </ModalContainer>
  );
};
