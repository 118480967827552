import Button from 'components/Button';
import { ButtonDiv, ModalContainer, StyledSpinner } from './styles';
import React from 'react';
import fusgif from '../../assets/gifs/fus.gif';
export const WaitingForConnect = (show: boolean) => {
  return (
    <ModalContainer isVisible={show}>
      <h1>Waiting connection</h1>
      <StyledSpinner viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </StyledSpinner>
    </ModalContainer>
  );
};
