import Button from 'components/Button';
import { ButtonDiv, ModalContainer } from './styles';
import React from 'react';
import fusgif from '../../assets/gifs/fus.gif';
export const ConnectionFail = (
  customText: string,
  show: boolean,
  connect: () => void,
) => {
  if (customText == '') {
    customText = 'Connection failed';
  }
  return (
    <ModalContainer isVisible={show}>
      <h1>{customText}</h1>
      <ButtonDiv>
        <Button
          title="Try again"
          onClick={() => {
            connect();
          }}
        ></Button>
      </ButtonDiv>
    </ModalContainer>
  );
};
