import { ImgPercentage } from 'components/PercentageImage';
import { BlinkImage, ModalDiv, ModalInfo, ModalProgress } from './styles';
import React from 'react';
import svgKs from '../../assets/kleverSafe.svg';
import svgGreen from '../../assets/klv-green.svg';
import warning from '../../assets/warning.svg';
import done from '../../assets/checked.png';

export const UpdatingModal: React.FC<{
  percentage: number;
  isVisible: boolean;
  isBlink: boolean;
  version: string;
  isDone: boolean;
}> = props => {
  const isVisible = props.isVisible || props.isDone;

  const getModalInfo = () => {
    if (props.isDone) {
      return (
        <>
          <h1>Update Done!</h1>
          <img src={done} height="100px" />
        </>
      );
    }
    return (
      <>
        <h1>Updating to version {props.version}</h1>
        <div>
          <img src={warning} />
          <h1>Please, do not switch pages while updating</h1>
        </div>
      </>
    );
  };

  return (
    <>
      <ModalDiv isVisible={isVisible}>
        <ModalProgress>
          <BlinkImage src={svgGreen} isVisible={props.isBlink} />
          <img src={svgKs} height="200px" />
          <h1>{props.percentage.toFixed(1)}%</h1>
        </ModalProgress>
        <ModalInfo>{getModalInfo()}</ModalInfo>
      </ModalDiv>
    </>
  );
};
