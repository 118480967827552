import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap"); 
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    margin: 0;
    background-color: black;
  }

  body, input, textarea, button {
    font-family: Montserrat, sans-serif;
  }

  input {
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }

  h1, h2, h3, h4, h5, h6, pre {
    font-weight: 600;
    font-family: "Manrope",sans-serif;
  }

  button {
    cursor: pointer;
  }

  span {
    color: ${props => props.theme.colors.white};
  }

  a {
    text-decoration: none;
  }
`;
