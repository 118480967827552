export interface Note {
  version: string;
  features: string[];
  bugs: string[];
  chains: string[];
}

interface Versions {
  versions: string[];
}

const paths = {
  url: 'https://ksafe-management-vjqvep4zoq-uc.a.run.app/public/firmware',
  binPath: '/bin',
  versionPath: '/ver',
  notesPath: '/notes',
  latestPath: '/latest',
  unstablePath: '/unstable',
  versionsPath: '/versions',
};

const isDevelop: boolean = process.env.REACT_APP_IS_DEVELOP === 'true';
console.log(isDevelop);

async function fetchApi<T>(route: string) {
  try {
    // 👇️ const response: Response
    const response = await fetch(route, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    // 👇️ const result: GetUsersResponse
    const result = (await response.json()) as T;

    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return undefined;
    } else {
      console.log('unexpected error: ', error);
      return undefined;
    }
  }
}

const getNoteByVersion = async (version: string) => {
  return fetchApi<Note>(
    paths.url + paths.notesPath + paths.versionPath + '/' + version,
  );
};

const getLatestNote = async () => {
  let request = paths.url + paths.notesPath + paths.latestPath;
  console.log(isDevelop);
  if (isDevelop == true) {
    request += paths.unstablePath;
  }

  return fetchApi<Note>(request);
};

const getLatestFirmwareUrl = () => {
  let url = paths.url + paths.binPath + paths.latestPath;
  if (isDevelop == true) {
    url += paths.unstablePath;
  }

  return url;
};

const getFirmwareUrlByVersion = (version: string) => {
  return paths.url + paths.binPath + paths.versionPath + '/' + version;
};

const getLatestNotes = async (lenght: number) => {
  const latestsVersionsRequests = paths.url + paths.versionsPath;
  const versions = await fetchApi<Versions>(latestsVersionsRequests);

  const notes: Note[] = [];

  const versionArr = versions!.versions;

  let start = versionArr.length - lenght;
  if (start < 0) {
    start = 0;
  }

  for (let i = start; i < versionArr.length; i++) {
    const note = await getNoteByVersion(versionArr[i]);
    notes.push(note!);
  }

  return notes;
};

const fus = {
  getNoteByVersion,
  getLatestNote,
  getLatestFirmwareUrl,
  getFirmwareUrlByVersion,
  getLatestNotes,
};

export default fus;
