import Button from 'components/Button';
import React from 'react';
import fusgif from 'assets/kleverSafeFus.svg';
import { ButtonDiv, ModalContainer } from './styles';
export const ProceedWithUpdateModal: React.FC<{
  show: boolean;
  callback: () => void;
}> = props => {
  return (
    <ModalContainer isVisible={props.show}>
      <h1>Your wallet is now in update mode</h1>
      <img src={fusgif} height="200px" />
      <ButtonDiv>
        <Button
          title="Update"
          onClick={() => {
            props.callback();
          }}
        ></Button>
      </ButtonDiv>
    </ModalContainer>
  );
};
