import React from 'react';
import { ksafe, SdkProvider, StorageProvider } from '@klever/extension';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/global';
import theme from './styles/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import FirmwareUpdatePage from 'pages/KsafeFus';
import dotenv from 'dotenv';
import KleverSafeUpdatePage from 'pages/KleverSafeUpdate';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SdkProvider>
          <GlobalStyle />
          <Routes>
            <Route path="/*" element={<KleverSafeUpdatePage />} />
          </Routes>
        </SdkProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
