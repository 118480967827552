import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalContainer = styled.div<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  opacity: ${p => (p.isVisible ? '1' : '0')};
  transition: opacity 1s;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
  z-index: 3;
  min-width: 20rem;
  top: 50%;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  h1 {
    padding: 1rem;
    font-size: 1.5rem;
  }
  img {
    border-radius: 1rem;
  }
`;

export const ButtonDiv = styled.div`
  width: auto;
  min-width: 10rem;
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  button {
    font-size: 1rem;
  }
  z-index: 2;
`;

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  margin: 1rem;

  & .path {
    stroke: #a432b9;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
